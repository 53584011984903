import {
  all,
  takeEvery,
  fork,
  call,
  put,
} from 'redux-saga/effects';
import _ from 'lodash';
import platformApi from 'services/platform';

import actions from './actions';

export function* getPromptsSummarySaga() {
  yield takeEvery(actions.FETCH_PROMPTS, function* ({  }) {
    const promptsSummary = yield call(platformApi.platformGetReq, {
      urlPath: 'prompts',
      disableNotification: true,
    });
    if (promptsSummary) {
      yield put({
        type: actions.FETCH_PROMPTS_SUCCESS,
        data: promptsSummary.prompts,
      });
    } 
  });
}

export function* updatePromptSaga() {
  yield takeEvery(actions.UPDATE_PROMPT, function* ({ data,cb }) {
    let url = data.isGlobal ? `prompts/${data.name}?isGlobal=true` : `prompts/${data.name}`
    const prompt = yield call(platformApi.platformPutReq, {
      urlPath: url,
      data,
      entityType:'prompt'
    });
    if (prompt) {
      yield put({
        type: actions.FETCH_PROMPTS,
      });
      cb?.()
    } 
  });
}

export function* deletePromptSaga() {
  yield takeEvery(actions.DELETE_PROMPT, function* ({ name,isGlobal,cb }) {
    let url = isGlobal ? `prompts/${name}?isGlobal=true` : `prompts/${name}`
    const prompt = yield call(platformApi.platformDelReq, {
      urlPath: url,
      entityType:'prompt'
    });
    yield put({
      type: actions.FETCH_PROMPTS,
    });
    cb?.()
  });
}

export default function* rootSaga() {
  yield all([
    fork(getPromptsSummarySaga),
    fork(updatePromptSaga),
    fork(deletePromptSaga)
  ]);
}
