import React from 'react';
import axios from 'axios';
import settings from 'settings';
import { store } from 'redux/store';
import {
  get, forOwn, omit, isString,
} from 'lodash';
import IntlMessages from 'components/utility/intlMessages';
import { showToast } from 'utils/toast';
// import { oktaAuth } from 'oktaAuth';

const baseHeaders = { 'Content-Type': 'application/json' };
const proxy = get(settings, 'api.proxy');
const apiUrl = get(settings, 'api.url');
const adminApiUrl = get(settings, 'api.adminUrl');
const rootPath = get(settings, 'api.rootPath');
const platformApiUrl = get(settings, 'api.serverContextMap.platform');

const entityUrlForPlatform = ({ entityType, id } = {}) => {
  const idPath = id ? `/${id}` : '';
  const entityPath = get(settings, `api.entityMap.${entityType}`, entityType);
  return platformApiUrl + entityPath + idPath;
};

function fetchAllForPlatform({ entityType, transform = (res) => res, params }) {
  const url = entityUrlForPlatform({ entityType });
  const headers = authHeaders({ entityType });
  return axios
    .get(url, { headers, params })
    .then((res) => res.data)
    .then(transform)
    .catch((err) => handleServerError(err, 'Retrieving', entityType));
}

// axios.interceptors.response.use(
//   function(response) {
//     console.log(response.status, 'from interceptor');

//     return response;
//   },
//   function(error) {
//     const { status } = error.response;
//     if (status === 401 || status === 403) {
//       showToast('error',{
//         task: (<div> Session Expired, please log in again</div>),
//         type: null,
//       })
//       setTimeout(() => {
//         console.log('logging out')
//         window.location.href = '/logout'
//       }, 3000)
//       // check for auth
//       // oktaAuth
//       //   .getTokenWithoutPrompt()
//       //   .then(function(res) {
//       //     // var tokens = res.tokens;
//       //     console.log('getTokenWithoutPrompt', res.tokens.accessToken);
//       //   })
//       //   .catch(function(err) {
//       //     // handle OAuthError or AuthSdkError (AuthSdkError will be thrown if app is in OAuthCallback state)
//       //     // need to signout if we dont get tokens
//       //   });
//     }
//     return Promise.reject(error);
//   }
// );

function createUrlVariableFilters(filters) {
  let result = '';
  forOwn(filters, (value, key) => {
    const filterName = get(settings, `api.filterNameMappings.${key}`, key);
    if (result === '') result += '?';
    result += `${filterName}=${value}`;
  });
  return result;
}

// TODO: We need to refactor this monster
const entityUrl = ({
  entityType,
  id,
  nameRoot = 'entityMap',
  filters,
  suffix = '',
} = {}) => {
  const state = store.getState();
  const hasAdminPrivileges = get(state, 'Profile.hasAdminPrivileges');
  const entityTypeRoot = entityType.split('/')[0];
  const context = get(settings, `api.entityContext.${entityTypeRoot}`, null);
  const contextPath = context && proxy ? `/${context}` : '';
  const entityNestingTemplate = get(
    settings,
    `api.nesting.${entityType}`,
    () => '',
  );
  const entityNestingPath = entityNestingTemplate({ filters });
  let entityPath = entityNestingPath
    + get(settings, `api.${nameRoot}.${entityType}`, entityType);
  const idPath = id ? `/${id}` : '';
  const filtersInUrl = get(settings, `api.filtersInUrl.${entityType}`, []);
  let urlVariables = createUrlVariableFilters({
    ...omit(filters, filtersInUrl),
  });
  if (entityType === 'ttl') urlVariables = '/settings/ttl';
  if (entityType === 'vp') {
    entityPath = '';
    urlVariables = 'version/current';
  }

  let isAdminPath = entityType === 'accounts'
    || (hasAdminPrivileges && state.App.activeAccountId != null);
  if (entityType === 'account' || entityType === 'user') {
    // We want to exclude theses entities because they are checking for authorization and not admin paths
    isAdminPath = false;
  }
  const baseUrl = isAdminPath ? adminApiUrl : apiUrl;
  const serverContextMap = get(settings, `api.serverContextMap.${context}`);
  const serverPath = !proxy && serverContextMap
    ? serverContextMap
    : baseUrl + contextPath + rootPath;
  return serverPath + entityPath + idPath + urlVariables + suffix;
};

function authHeaders({ activeAccountIdOverride } = {}) {
  const state = store.getState();
  const token = get(state, 'Auth.token', '');

  const authServiceHeader = { Authorization: `Bearer ${token}` };
  const activeAccountId = activeAccountIdOverride || state.App.activeAccountId;
  const accountIdHeader = activeAccountId !== null ? { 'VoiceBase-Account-ID': activeAccountId } : {};
  return {
    "VoiceBase-VPN-Secret" : "qwerty",
    ...baseHeaders,
    ...authServiceHeader,
    ...accountIdHeader,
  };
}

function success(res, task, type = 'test', needResponse = false) {
  const state = store.getState();
  const messages = get(state, 'App.messages');
  messages[type] = showToast('success', { task, type });
  return needResponse ? res : res.data;
}

/**
 * There are currently three types of error we need to handle
 * 1. Direct calls to Platform
 * 2. Calls to EA Backend
 * 3. Calls to EA Backend that calls Platform
 */
function handleServerError(err, task, type) {
  const responseData = get(err, 'response.data');
  // 1.
  const isPlatformError = get(responseData, 'errors[0].error');
  if (isPlatformError) {
    const platformErrors = responseData.errors.map((i) => i.error);
    // Limit the number of errors to display to 5, we do not want to potentially display 100s of errors.

    showToast('error', {
      task: (
        <div>
          {platformErrors.length > 5 && (
            <span>5 of {platformErrors.length} errors</span>
          )}
          {platformErrors.slice(0, 5).map((i) => (
            <div key={i}>{i}</div>
          ))}
        </div>
      ),
      type: null,
    });
    return;
  }

  // 2. Calls to EA Backend
  let messages = !responseData
    ? null
    : isString(responseData)
      ? [responseData]
      : 'details' in responseData
        ? responseData.details
        : 'message' in responseData
          && responseData.message !== 'No message available'
          ? [responseData.message]
          : null;
  if (!messages) messages = get(responseData, 'errors', null);

  // 3. Calls to EA Backend that calls Platform
  if (messages && messages[0].includes('message=')) {
    let parsedMessage = messages[0].split(/([{}])/);
    parsedMessage = parsedMessage
      .find((i) => i.includes('message='))
      ?.split('message=');
    if (parsedMessage) messages = [parsedMessage.pop()];
  }

  showToast('error', {
    task: (
      <div>
        <div>
          Could not process request: {task || '...'} <b>{type || ''}</b>
          {messages && ':'}
        </div>
        {messages && (
          <div>
            {messages.map((m, i) => (
              <div key={i}>{m}</div>
            ))}
          </div>
        )}
      </div>
    ),
    type: 'error',
  });
  return responseData;
}

function fetchAllGet({
  entityType,
  transform = (res) => res,
  filters,
  params,
  activeAccountIdOverride,
}) {
  const url = entityUrl({ entityType, filters });
  const headers = authHeaders({ entityType, activeAccountIdOverride });
  return axios
    .get(url, { headers, params })
    .then((res) => res.data)
    .then(transform)
    .catch((err) => handleServerError(err, 'Retrieving', entityType));
}

function fetchByPost({
  entityType,
  data = [],
  filters,
  params,
  activeAccountIdOverride,
}) {
  const url = entityUrl({ entityType, filters });
  const headers = authHeaders({ entityType, activeAccountIdOverride });

  if (!data.length) return

  return axios.post(url, data, { headers, params })
    .then((res) => res.data)
    .catch((err) => {
      // Error due to auth permissions
      if (err?.message.includes(403)) {
        return
      }
      return handleServerError(err, 'Retrieving', entityType)
    });
}

function fetchAll(props) {
  return fetchAllGet(props);
}

function createItem({
  entityType,
  data,
  filters,
  params,
  disableNotification = false,
  activeAccountIdOverride,
}) {
  const url = entityUrl({ entityType, filters });
  const headers = authHeaders({ entityType, activeAccountIdOverride });
  return axios
    .post(url, data, {
      headers,
      params,
    })
    .then((res) => {
      if (disableNotification) return res.data;
      return success(
        res,
        'Successfully created',
        <IntlMessages id={`entityType.singular.${entityType}`} />,
      );
    })
    .then((res) => res)
    .catch((err) => handleServerError(
      err,
      'Creating',
      <IntlMessages id={`entityType.singular.${entityType}`} />,
    ));
}

function cancelJobBatchItem({
  entityType,
  data,
  id,
  disableNotification = false,
}) {
  const url = entityUrl({ entityType });
  const headers = authHeaders();
  return axios
    .post(`${url}/${id}/cancel`, data, {
      headers,
    })
    .then((res) => {
      if (disableNotification) return res.data;
      return success(
        res,
        'Successfully cancelled',
        <IntlMessages id={`entityType.singular.${entityType}`} />,
      );
    })
    .then((res) => res)
    .catch((err) => handleServerError(
      err,
      'Cancelling',
      <IntlMessages id={`entityType.singular.${entityType}`} />,
    ));
}

function createArray({
  entityType, data, filters, nestedPayload = true,
}) {
  const url = entityUrl({ entityType, filters });
  const headers = authHeaders({ entityType });
  const payload = nestedPayload ? { [entityType]: data } : data;
  return axios
    .post(`${url}/bulk`, payload, {
      headers,
    })
    .then((res) => success(
      res,
      'Successfully created',
      <IntlMessages id={`entityType.singular.${entityType}`} />,
    ))
    .then((res) => res)
    .catch((err) => handleServerError(
      err,
      'Creating',
      <IntlMessages id={`entityType.singular.${entityType}`} />,
    ));
}

function fetchItem({
  entityType,
  id,
  params,
  activeAccountIdOverride,
  filters,
  disableNotification,
  needResponse = false,
  suffix = '',
  transform = (res) => res
}) {
  const url = entityUrl({ entityType, id, filters, suffix });
  const headers = authHeaders({ entityType, activeAccountIdOverride });
  return axios
    .get(url, {
      headers,
      params,
    })
    .then((res) => (needResponse ? res : res.data))
    .then(transform)
    .catch((err) => (!disableNotification
      ? handleServerError(
        err,
        'Retrieving',
        <IntlMessages id={`entityType.singular.${entityType}`} />,
      )
      : err));
}

function fetchItemPromise({
  entityType,
  id,
  params,
  activeAccountIdOverride,
  filters,
  suffix = '',
}) {
  const url = entityUrl({ entityType, id, filters, suffix });
  const headers = authHeaders({ entityType, activeAccountIdOverride });
  return axios
    .get(url, {
      headers,
      params,
    });
}

function editItem({
  entityType,
  data,
  id,
  filters,
  params,
  disableNotification = false,
  activeAccountIdOverride,
  suffix = '',
  needResponse = false,
  toastAction = 'Updated'
}) {
  const url = entityUrl({
    entityType,
    id,
    filters,
    suffix,
  });
  const headers = authHeaders({ entityType, activeAccountIdOverride });
  return axios
    .put(url, data, {
      headers,
      params,
    })
    .then((res) => {
      if (disableNotification) return needResponse ? res : res.data;
      return success(
        res,
        `${toastAction} Successfully`,
        <IntlMessages id={`entityType.singular.${entityType}`} />,
        needResponse,
      );
    })
    .then((res) => res)
    .catch((err) => handleServerError(
      err,
      'Updating',
      <IntlMessages id={`entityType.singular.${entityType}`} />,
    ));
}

function patchItem({
  entityType,
  data,
  activeAccountIdOverride,
  params,
  filters,
}) {
  const url = entityUrl({ entityType, filters });
  const headers = authHeaders({ entityType, activeAccountIdOverride });
  return axios
    .patch(url, data, {
      headers,
      params,
    })
    .then((res) => success(
      res,
      'Patched',
      <IntlMessages id={`entityType.singular.${entityType}`} />,
    ))
    .then((res) => res)
    .catch((err) => handleServerError(
      err,
      'Patching',
      <IntlMessages id={`entityType.singular.${entityType}`} />,
    ));
}

function deleteItem({
  entityType, id, filters, params, name
}) {
  const hardDeleteTag = false ? '?hardDelete=true' : '';
  let sanitizedId = entityType === 'categories' ? name : id;
  if (entityType === 'spottingGroups') {
    // EA-3238 we need to encode uri params for deleting spotting groups because they go into the uri
    sanitizedId = encodeURIComponent(sanitizedId);
  }
  const url = entityUrl({ entityType, id: sanitizedId, filters }) + hardDeleteTag;
  const headers = authHeaders({ entityType });
  return axios
    .delete(url, {
      headers,
      params,
    })
    .then((res) => {
      success(
        res,
        'Deleted',
        <IntlMessages id={`entityType.singular.${entityType}`} />,
      );
      return res;
    })
    .catch((err) => handleServerError(
      err,
      'Deleting',
      <IntlMessages id={`entityType.singular.${entityType}`} />,
    ));
}

function fetch({
  entityType,
  filters,
  params,
  activeAccountIdOverride,
  disableNotification = false,
}) {
  const url = entityUrl({ entityType, filters });
  const headers = authHeaders({ entityType, activeAccountIdOverride });
  return axios
    .get(url, { headers, params })
    .then((res) => res.data)
    .catch((err) => (disableNotification
      ? console.error(err)
      : handleServerError(
        err,
        'getting',
        <IntlMessages id={`entityType.singular.${entityType}`} />,
      )));
}

function getBaseURL(entityType = '') {
  const state = store.getState();
  const hasAdminPrivileges = get(state, 'Profile.hasAdminPrivileges');
  const entityTypeRoot = entityType.split('/')[0];
  const context = get(settings, `api.entityContext.${entityTypeRoot}`, null);
  const contextPath = context && proxy ? `/${context}` : '';
  let isAdminPath = entityType === 'accounts'
    || (hasAdminPrivileges && state.App.activeAccountId != null);
  if (
    (entityType === 'account'
      || entityType === 'user'
      || entityType?.length === 0)
    && !hasAdminPrivileges
  ) {
    // We want to exclude theses entities because they are checking for authorization and not admin paths
    isAdminPath = false;
  }
  const baseUrl = isAdminPath ? adminApiUrl : apiUrl;
  const serverContextMap = get(settings, `api.serverContextMap.${context}`);
  const serverPath = !proxy && serverContextMap
    ? serverContextMap
    : baseUrl + contextPath + rootPath;
  return serverPath;
}

function getReq({ url, disableNotification = false, msg = '', appendUrl = true, activeAccountIdOverride='' }) {
  const baseURL = getBaseURL();
  const headers = authHeaders({activeAccountIdOverride});
  let completeURL = baseURL + url;
  if (!appendUrl) completeURL = url;
  return axios
    .get(completeURL, { headers })
    .then((res) => {
      if (res.status === 200 && !disableNotification) { showToast('success', { task: msg }); }
      return res;
    })
    .catch((err) => {
      disableNotification
        ? console.log(err)
        : handleServerError(err, 'Failed', '');
    });
}

function getImageReq({ url, disableNotification = false, msg = '', appendUrl = true }) {
  const baseURL = getBaseURL();
  const headers = authHeaders();
  let completeURL = baseURL + url;
  if (!appendUrl) completeURL = url;
  return axios
    .get(completeURL, { responseType: "arraybuffer", headers })
    .then((res) => {
      if (res?.status === 200 && !disableNotification) { showToast('success', { task: msg }); }
      return res;
    })
    .catch((err) => {
        // console.log(err)
    });
}

function putReq({
  url, disableNotification = false, msg = '', data, activeAccountIdOverride=''
}) {
  const baseURL = getBaseURL();
  const headers = authHeaders({activeAccountIdOverride});
  const completeURL = baseURL + url;
  return axios
    .put(completeURL, data, { headers })
    .then((res) => {
      if (res.status === 200 && !disableNotification) { showToast('success', { task: msg }); }
      return res;
    })
    .catch((err) => (disableNotification
      ? console.error(err)
      : handleServerError(err, 'Failed', '')));
}

function deleteReq({ url, disableNotification = false, msg = '' }) {
  const baseURL = getBaseURL();
  const headers = authHeaders();
  const completeURL = baseURL + url;
  return axios
    .delete(completeURL, { headers })
    .then((res) => {
      if (res.status === 200 && !disableNotification) { showToast('success', { task: msg }); }
      return res;
    })
    .catch((err) => {
      disableNotification
        ? console.log(err)
        : handleServerError(err, 'Failed', '');
    });
}

function postReq({
  url, disableNotification = false, msg = '', data,
}) {
  const baseURL = getBaseURL();
  const headers = authHeaders();
  const completeURL = baseURL + url;
  return axios
    .post(completeURL, data, { headers })
    .then((res) => {
      if (res.status === 200 && !disableNotification) { showToast('success', { task: msg }); }
      return res;
    })
    .catch((err) => (disableNotification
      ? (console.error(err))
      : handleServerError(err, 'Failed', '')));
}

function toggleAccountProduct({
  productType,
  accountId,
  isActivating,
  payload,
}) {
  const productActionIntent = isActivating ? 'activate' : 'deactivate';
  const url = `${adminApiUrl}/v3/accounts/product/${productType}/${productActionIntent}`;
  const headers = authHeaders({ activeAccountIdOverride: accountId });
  return axios
    .post(url, payload, { headers })
    .then((res) => res.data)
    .catch((err) => handleServerError(
      err,
      'getting',
      <IntlMessages id="entityType.singular.product" />,
    ));
}

function fetchPlatformItem({
  entityType, id, params, filters,
}) {
  const url = entityUrlForPlatform({ entityType, id, filters });
  const headers = authHeaders({ entityType });
  return axios
    .get(url, {
      headers,
      params,
    })
    .then((res) => res.data)
    .then((res) => res)
    .catch((err) => {
      console.error('ERROR');
      handleServerError(
        err,
        'Retrieving',
        <IntlMessages id={`entityType.singular.${entityType}`} />,
      );
    });
}

function postReqFile({
  url, disableNotification = false, msg = '', data,
}) {
  const baseURL = getBaseURL();
  const headers = authHeaders();

  const completeURL = baseURL + url;
  return axios
    .post(completeURL, data, { headers })
    .then((res) => {
      if (res.status === 200 && !disableNotification) { showToast('success', { task: msg }); }
      return res;
    })
    .catch((err) => (disableNotification
      ? (console.error(err), Promise.reject(err))
      : handleServerError(err, 'Failed', ''), Promise.reject(get(err, 'response.data'))));
}

function postItemWithFormData({ url, disableNotification = false, msg = '', data, entityType }) {
  const baseURL = getBaseURL();
  const headers = authHeaders();
  const completeURL = baseURL + url;

  const bodyFormData = new FormData();
  for (const [key, value] of Object.entries(data)) {
    bodyFormData.append(key, value);
  }
  return axios
    .post(completeURL, bodyFormData, {
      headers: {
        ...headers,
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((res) => {
      if (res.status === 200 && !disableNotification) { showToast('success', { task: msg }); }
      return res;
    })
    .catch((err) => (disableNotification
      ? (console.error(err), Promise.reject(err))
      : handleServerError(err, 'Failed', ''), Promise.reject(get(err, 'response.data'))));
}

export default {
  fetchAll,
  fetchItem,
  fetchItemPromise,
  createItem,
  createArray,
  editItem,
  patchItem,
  deleteItem,
  handleServerError,
  baseHeaders,
  authHeaders,
  entityUrl,
  toggleAccountProduct,
  fetch,
  success,
  get: getReq,
  put: putReq,
  delete: deleteReq,
  post: postReq,
  fetchPlatformItem,
  fetchAllForPlatform,
  postFile: postReqFile,
  getImageReq,
  fetchByPost,
  cancelJobItem: cancelJobBatchItem,
  postItemWithFormData: postItemWithFormData
};
