/** @prettier */
import { getBaseReducer } from 'utils';
import { communicationModel as model } from 'models/communication';
import {
  UPLOADER_FIELD,
  UPLOADED_FILE_INTERACTION_TYPE,
  CONNECTION_FORMAT_TYPE,
} from 'components/UploadMedia/UploadMediaConstants';
import _ from 'lodash';
import actions from './actions';

const initialConfig = {
  priority: '',
  categories: [],
  prediction: {
    detectors: [],
  },
  speechModel: {
    features: [],
    language: '',
    model: '',
  },
  metrics: [],
  publish: {
    callbacks: [],
    enableAnalyticIndexing: true,
  },
  ingest: {
    channels: [
      {
        speakerName: 'Agent',
      },
      {
        speakerName: 'Caller',
      },
    ],
  },
  summary:{}
};

const initState = {
  hasCSVFileBeenUploaded: false,
  uploadMediaUrl: '',
  uploadName: '',
  uploadDescription: '',
  csvFileHeaders: [],
  parsedCSVFileArray: [],
  uploaderField: UPLOADER_FIELD.STANDARD_UPLOADER,
  fileInteractionType: '',
  config: initialConfig,
  customVocabularyListItems: {
    items: [],
    isVocabularyItemListLoaded: false,
  },
  advancedConfig: {
    newConfig: {},
    existingConfig: {},
    newConfigFile: {},
    existingConfigFile: {},
  },
  metadataFromUploadedFile: {},
  enteredMetaDataDetail: {},
  metadata: {},
  advancedMetadata: {
    existingMetadata: {},
    existingMetadataFile: {},
    newMetadataDetail: {},
    newMetaDataFile: {},
  },
  uploadedMetadataJSONFile: null,
};

export default function uploadMediaReducer(state = initState, action) {
  switch (action.type) {
    case actions.RESET_CONFIG_INGEST:
      return {
        ...state,
        config: {
          ...state.config,
          ingest: {},
        },
      };
    case actions.RESET_CONFIG_INGEST_CHANNELS:
      return {
        ...state,
        config: {
          ...state.config,
          ingest: { channels: [] },
        },
      };
    case actions.RESET_UPLOAD_MEDIA_FORM:
      return {
        ...initState,
        metadataFromUploadedFile: {},
        enteredMetaDataDetail: {},
        metadata: {},
      };
    case actions.RESET_UPLOAD_CONFIG: {
      const { fileInteractionType, connectionFormInputs } = state;
      const isTextChatInteraction =
        fileInteractionType === UPLOADED_FILE_INTERACTION_TYPE.TEXT ||
        connectionFormInputs?.format === CONNECTION_FORMAT_TYPE.CHAT;
      return {
        ...state,
        config: {
          ...initialConfig,
          speechModel: {
            ...initialConfig.speechModel,
            features: [],
          },
          metrics: [],
          prediction: {
            detectors: [],
          },
          priority: isTextChatInteraction ? 'low' : 'normal',
          publish: {
            ...initialConfig.publish,
            callbacks: [],
          },
        },
      };
    }
    case actions.SET_CONFIG_WITH_ADVANCED_CONFIG:
      return {
        ...state,
        config: state.advancedConfig.newConfig,
      };
    case actions.SET_NEW_ADVANCED_CONFIG:
      return {
        ...state,
        advancedConfig: {
          ...state.advancedConfig,
          newConfig: action.advancedConfig,
        },
      };
    case actions.SET_NEW_ADVANCED_CONFIG_FILE:
      return {
        ...state,
        advancedConfig: {
          ...state.advancedConfig,
          newConfigFile: action.advancedConfigFile,
        },
      };

    case actions.SET_METADATA_WITH_ADVANCED_METADATA:
      return {
        ...state,
        metadata: state.advancedMetadata.newMetadataDetail,
      };

    case actions.SET_METADATA_WITH_STANDARD_METADATA:
      return {
        ...state,
        metadata: action.standardMetadata,
      };

    case actions.UPDATE_METADATA_WITH_ENTERED_METADATA:
      return {
        ...state,
        metadata: {
          ...state.metadata,
          ...action.updatedMetadata,
        },
      };
    case actions.SET_METADATA_STANDARD_UPLOAD_FILE:
      return {
        ...state,
        uploadedMetadataJSONFile: action.file,
      };
    case actions.SET_METADATA_FROM_UPLOADED_FILE:
      return {
        ...state,
        metadataFromUploadedFile: action.metadataFromUploadedFile,
      };

    case actions.SET_NEW_ADVANCED_METADATA:
      return {
        ...state,
        advancedMetadata: {
          ...state.advancedMetadata,
          newMetadataDetail: action.advancedMetadata,
        },
      };
    case actions.SET_NEW_ADVANCED_METADATA_FILE:
      return {
        ...state,
        advancedMetadata: {
          ...state.advancedMetadata,
          newMetaDataFile: action.advancedMetadataFile,
        },
      };
    case actions.SET_STANDARD_ENTERED_METADATA:
      return {
        ...state,
        enteredMetaDataDetail: action.enteredMetaDataDetail,
      };
    case actions.SET_CSV_FILE_UPLOAD_STATE:
      return {
        ...state,
        hasCSVFileBeenUploaded: action.hasCSVFileBeenUploaded,
      };
    case actions.SET_UPLOAD_MEDIA_URL:
      return {
        ...state,
        uploadMediaUrl: action.uploadMediaUrl,
      };
    case actions.SET_UPLOAD_NAME:
      return {
        ...state,
        uploadName: action.uploadName,
      };
    case actions.SET_UPLOAD_DESCRIPTION:
      return {
        ...state,
        uploadDescription: action.uploadDescription,
      };
    case actions.SET_CSV_FILE_HEADERS:
      return {
        ...state,
        csvFileHeaders: action.csvFileHeaders,
      };
    case actions.SET_PARSED_CSV_FILE_ARRAY:
      return {
        ...state,
        parsedCSVFileArray: action.parsedCSVFileArray,
      };
    case actions.SET_UPLOADER_FIELD:
      return {
        ...state,
        uploaderField: action.uploaderField,
      };
    case actions.SET_UPLOADED_FILE_INTERACTION_TYPE: {
      const isTextChatInteraction =
        action.fileInteractionType === UPLOADED_FILE_INTERACTION_TYPE.TEXT ||
        action.fileInteractionType === CONNECTION_FORMAT_TYPE.CHAT;

      return {
        ...state,
        fileInteractionType: action.fileInteractionType,
        config: {
          ...state.config,
          priority: isTextChatInteraction ? 'low' : 'normal',
        },
      };
    }
    case actions.SET_SPEECH_MODEL_NAME:
      return {
        ...state,
        config: {
          ...state.config,
          speechModel: {
            ...state.config.speechModel,
            model: action.speechModelName,
          },
        },
      };
    case actions.SET_SPEECH_MODEL_LANGUAGE:
      return {
        ...state,
        config: {
          ...state.config,
          speechModel: {
            ...state.config.speechModel,
            language: action.speechModelLanguage,
          },
        },
      };
    case actions.SET_SPEECH_MODEL_FEATURES: {
      let featuresList = state.config.speechModel.features;
      if (!action.event) {
        featuresList = featuresList.filter((feature) => feature !== action.speechModelFeatures);
      } else if (_.isArray(action.speechModelFeatures)) {
        featuresList = _.merge(featuresList, action.speechModelFeatures);
      } else {
        featuresList.push(action.speechModelFeatures);
      }

      return {
        ...state,
        config: {
          ...state.config,
          speechModel: {
            ...state.config.speechModel,
            features: featuresList,
          },
        },
      };
    }
    case actions.SET_KNOWLEDGE_ENABLE_DISCOVERY:
      return {
        ...state,
        config: {
          ...state.config,
          knowledge: {
            ...state.config.knowledge,
            enableDiscovery: action.enableDiscovery,
          },
        },
      };
    case actions.SET_KNOWLEDGE_ENABLE_EXTERNAL_DATA_SOURCES:
      return {
        ...state,
        config: {
          ...state.config,
          knowledge: {
            ...state.config.knowledge,
            enableExternalDataSources: action.enableExternalDataSources,
          },
        },
      };
    case actions.SET_CONVERSATION_VERB_NOUN_PAIRS_ENABLED:
      return {
        ...state,
        config: {
          ...state.config,
          conversation: {
            ...state.config.conversation,
            verbNounPairsEnabled: action.verbNounPairsEnabled,
          },
        },
      };
    case actions.SET_PUBLISH_ENABLE_ANALYTIC_INDEXING:
      return {
        ...state,
        config: {
          ...state.config,
          publish: {
            ...state.config.publish,
            enableAnalyticIndexing: action.enableAnalyticIndexing,
          },
        },
      };
    case actions.SET_CATEGORIES_ALL_CATEGORIES: {
      let newState = state;
      if (!action.allCategories) {
        delete newState.config?.categories;
      }
      if (action.allCategories) {
        newState = {
          config: {
            ...newState.config,
            categories: [
              {
                allCategories: action.allCategories,
              },
            ],
          },
        };
      }

      return {
        ...state,
        config: {
          ...newState.config,
        },
      };
    }
    case actions.SET_SPEAKER_SENTIMENTS:
      return {
        ...state,
        config: {
          ...state.config,
          speakerSentiments: action.speakerSentiments,
        },
      };
    case actions.SET_ENTITY_ENABLE_ENTITY_EXTRACTION:
      return {
        ...state,
        config: {
          ...state.config,
          entity: {
            ...state.config.entity,
            enableEntityExtraction: action.enableEntityExtraction,
          },
        },
      };
    case actions.SET_PREDICTION_DETECTORS:
      return {
        ...state,
        config: {
          ...state.config,
          prediction: {
            ...state.config.prediction,
            detectors: [...state.config.prediction.detectors, action.predictionDetectors],
          },
        },
      };
    case actions.SET_CONFIG_METRICS:
      return {
        ...state,
        config: {
          ...state.config,
          metrics: action.metricsData,
        },
      };
    case actions.SET_INGEST_CHANNELS:
      return {
        ...state,
        config: {
          ...state.config,
          ingest: {
            channels: action.ingestChannels,
          },
        },
      };
    case actions.SET_INGEST:
      return {
        ...state,
        config: {
          ...state.config,
          ingest: action.ingest,
        },
      };
    case actions.SET_TRANSCRIPT_FORMATTING_ENABLE_NUMBER_FORMATTING:
      return {
        ...state,
        config: {
          ...state.config,
          transcript: {
            ...state.config.transcript,
            formatting: {
              enableNumberFormatting: action.enableNumberFormatting,
            },
          },
        },
      };
    case actions.SET_CONFIG_PRIORITY:
      return {
        ...state,
        config: { ...state.config, priority: action.priority },
      };

    case actions.SET_CUSTOM_VOCABULARY_LIST_ITEMS:
      return {
        ...state,
        customVocabularyListItems: {
          items: action.customVocabularyListItems,
          isVocabularyItemListLoaded: action.isVocabularyItemListLoaded,
        },
      };
    case actions.SET_CONFIG_CUSTOM_VOCABULARY:
      const vocabularies = action.vocabularies;
      const terms = state?.config?.vocabularies?.find((val) => val.terms);
      if (terms) {
        vocabularies.push(terms);
      }

      return {
        ...state,
        config: {
          ...state.config,
          vocabularies,
        },
      };

    case actions.SET_CONFIG_ADHOC_VOCABULARY_LIST: {
      const vocab = state.config.vocabularies;
      const tempVocab = _.set({}, action.path, action.data);
      const newVocab = _.mergeWith({ vocabularies: vocab }, tempVocab);

      // set default weight value to 0 if weight property not present
      newVocab.vocabularies?.forEach((term) => {
        if (!term) return;
        if (term.terms) {
        }
        term.terms?.forEach((term) => {
          if (term && !term.weight) {
            term.weight = 0;
          }
        });
      });
      return {
        ...state,
        config: {
          ...state.config,
          vocabularies: newVocab.vocabularies,
        },
      };
    }

    case actions.SET_CONFIG_VOCABULARIES:
      if (action.vocabularies === undefined) {
        return state; 
      }
      return {
        ...state,
        config: {
          ...state.config,
          vocabularies: action.vocabularies,
        },
      };

    case actions.SET_CONFIG_ADVANCED_PUNCTUATION: {
      let { features } = state.config.speechModel;
      if (action.event) {
        features.push('advancedPunctuation');
      } else {
        features = features.filter((feat) => feat !== 'advancedPunctuation');
      }
      return {
        ...state,
        config: {
          ...state.config,
          speechModel: {
            ...state.config.speechModel,
            features,
          },
        },
      };
    }
    case actions.SET_CONFIG_VOICEMAIL: {
      let { features } = state.config.speechModel;
      if (action.event) {
        features.push('voicemail');
      } else {
        features = features.filter((feat) => feat !== 'voicemail');
      }
      return {
        ...state,
        config: {
          ...state.config,
          speechModel: {
            ...state.config.speechModel,
            features,
          },
        },
      };
    }
    case actions.SET_CONFIG_CONVERT_FILE_FORMAT: {
      return {
        ...state,
        config: {
          ...state.config,
          publish: {
            ...state.config.publish,
            audioTranscode: action.fileFormat,
          },
        },
      };
    }
    case actions.SET_KEY_WORD_SPOTTING_LIST:
      return {
        ...state,
        config: {
          ...state.config,
          ...action.spottingList,
        },
      };
    case actions.SET_CONFIG_NUMBER_BLASTER: {
      const { fileInteractionType, config, connectionFormInputs } = state;
      let { detectors: filteredDetectors } = config.prediction;
      if (!action.event) {
        filteredDetectors = filteredDetectors.filter((detector) => detector.detectorName !== 'Number');
      } else {
        filteredDetectors.push({
          detectorName: 'Number',
          redactor: {
            transcript: {
              replacement: '[redacted]',
            },
            ...((fileInteractionType === UPLOADED_FILE_INTERACTION_TYPE.VOICE ||
              connectionFormInputs?.format === CONNECTION_FORMAT_TYPE.SPEECH) && {
              audio: {
                tone: 170,
                gain: 0.25,
              },
            }),
          },
        });
      }

      return {
        ...state,
        config: {
          ...state.config,
          prediction: {
            ...state.config.prediction,
            detectors: [...filteredDetectors],
          },
        },
      };
    }
    case actions.SET_CONFIG_PCI_DETECTION: {
      let { detectors: filteredDetectors } = state.config.prediction;
      const pciDetector = filteredDetectors.find((detector) => detector.detectorName === 'PCI');
      if (action.pciDetection === 'disableDetection') {
        filteredDetectors = filteredDetectors.filter((detector) => detector.detectorName !== 'PCI');
      } else if (pciDetector) {
        pciDetector.parameters[0].value = action.pciDetection;
      } else {
        filteredDetectors.push({
          detectorName: 'PCI',
          parameters: [
            {
              parameter: 'detectionLevel',
              value: action.pciDetection,
            },
          ],
        });
      }

      return {
        ...state,
        config: {
          ...state.config,
          prediction: {
            ...state.config.prediction,
            detectors: [...filteredDetectors],
          },
        },
      };
    }

    case actions.SET_CONFIG_PCI_REDACTION: {
      const { fileInteractionType, config, connectionFormInputs } = state;
      const { detectors: filteredDetectors } = config.prediction;
      const pciDetector = filteredDetectors.find((detector) => detector.detectorName === 'PCI');

      if (pciDetector) {
        if (action.pciRedaction) {
          pciDetector.redactor = {
            transcript: {
              replacement: '[redacted]',
            },
            ...((fileInteractionType === UPLOADED_FILE_INTERACTION_TYPE.VOICE ||
              connectionFormInputs?.format === CONNECTION_FORMAT_TYPE.SPEECH) && {
              audio: {
                tone: 170,
                gain: 0.25,
              },
            }),
          };
        } else {
          delete pciDetector.redactor;
        }
      }

      return {
        ...state,
        config: {
          ...state.config,
          prediction: {
            ...state.config.prediction,
            detectors: [...filteredDetectors],
          },
        },
      };
    }

    case actions.SET_CONFIG_SSN_DETECTION: {
      let { detectors: filteredDetectors } = state.config.prediction;
      const pciDetector = filteredDetectors.find((detector) => detector.detectorName === 'SSN');
      if (action.ssnDetection === 'disableDetection') {
        filteredDetectors = filteredDetectors.filter((detector) => detector.detectorName !== 'SSN');
      } else if (pciDetector) {
        pciDetector.parameters[0].value = action.ssnDetection;
      } else {
        filteredDetectors.push({
          detectorName: 'SSN',
          parameters: [
            {
              parameter: 'detectionLevel',
              value: action.ssnDetection,
            },
          ],
        });
      }

      return {
        ...state,
        config: {
          ...state.config,
          prediction: {
            ...state.config.prediction,
            detectors: [...filteredDetectors],
          },
        },
      };
    }

    case actions.SET_CONFIG_SSN_REDACTION: {
      const { fileInteractionType, config, connectionFormInputs } = state;
      const { detectors: filteredDetectors } = config.prediction;
      const pciDetector = filteredDetectors.find((detector) => detector.detectorName === 'SSN');

      if (pciDetector) {
        if (action.ssnRedaction) {
          pciDetector.redactor = {
            transcript: {
              replacement: '[redacted]',
            },
            ...((fileInteractionType === UPLOADED_FILE_INTERACTION_TYPE.VOICE ||
              connectionFormInputs?.format === CONNECTION_FORMAT_TYPE.SPEECH) && {
              audio: {
                tone: 170,
                gain: 0.25,
              },
            }),
          };
        } else {
          delete pciDetector.redactor;
        }
      }

      return {
        ...state,
        config: {
          ...state.config,
          prediction: {
            ...state.config.prediction,
            detectors: [...filteredDetectors],
          },
        },
      };
    }

    case actions.SET_CONFIG_SWEAR_WORD_FILTER:
      return {
        ...state,
        config: {
          ...state.config,
          transcript: {
            ...state.config.transcript,
            contentFiltering: {
              enableProfanityFiltering: action.swearWordFilter,
            },
          },
        },
      };
    case actions.SET_CONFIG_PREDICTION_CLASSIFIERS:
      return {
        ...state,
        config: {
          ...state.config,
          prediction: {
            ...state.config.prediction,
            classifiers: action.predictionClassifiers,
          },
        },
      };

    case actions.SET_CONFIG_SELECT_CATEGORIES: {
      return {
        ...state,
        config: {
          ...state.config,
          categories: action.selectCategories,
        },
      };
    }

    case actions.SET_CONFIG_CONVERSATION_METRICS: {
      let conversationMetrics = state.config.metrics;

      if (action.event) {
        conversationMetrics.push({
          metricGroupName: action.metrics,
        });
      } else if (!action.event) {
        conversationMetrics = conversationMetrics.filter((metric) => metric.metricGroupName !== action.metrics);
      }

      return {
        ...state,
        config: {
          ...state.config,
          metrics: conversationMetrics,
        },
      };
    }
    case actions.SET_NEW_CONVERSATION_METRICS: {
      return {
        ...state,
        config: {
          ...state.config,
          metrics: action.metrics,
        },
      };
    }
    case actions.SET_CONFIG_CALLBACK_URLS: {
      let { callbacks } = state.config.publish;
      if (!callbacks.length) {
        callbacks.push(action.urlAnalytics);
      } else {
        const matchingCallback = callbacks.find((callback) => callback.type === action.callbackType);

        if (matchingCallback) {
          if (action.urlAnalytics) {
            matchingCallback.url = action.urlAnalytics.url;
          } else {
            callbacks = callbacks.filter((callback) => callback.type !== action.callbackType);
          }
        } else {
          callbacks.push(action.urlAnalytics);
        }
      }
      return {
        ...state,
        config: {
          ...state.config,
          publish: {
            ...state.config.publish,
            callbacks: [...callbacks],
          },
        },
      };
    }
    case actions.SET_CONFIG_CALLBACK_METHODS: {
      const { callbacks } = state.config.publish;
      callbacks?.forEach((callback) => {
        if (callback.type === action.callbackType) {
          // eslint-disable-next-line no-param-reassign
          callback.method = action.method;
        }
      });
      return {
        ...state,
        config: {
          ...state.config,
          publish: {
            ...state.config.publish,
            callbacks: [...callbacks],
          },
        },
      };
    }
    case actions.SET_CONFIG_CALLBACK_SKIP_IF_NOT_REDACTED: {
      const { callbacks } = state.config.publish;
      callbacks?.forEach((callback) => {
        if (callback.type === action.callbackType) {
          // eslint-disable-next-line no-param-reassign
          callback.skipIfNotRedacted = action.event;
        }
      });
      return {
        ...state,
        config: {
          ...state.config,
          publish: {
            ...state.config.publish,
            callbacks: [...callbacks],
          },
        },
      };
    }
    case actions.SET_CONNECTION_DATA: {
      const config = state.config;
      if (action.connectionData.format === 'SPEECH') {
        config.ingest = {
          channels: [
            {
              speakerName: 'Agent',
            },
            {
              speakerName: 'Caller',
            },
          ],
        };
      } else {
        delete config.ingest;
      }
      return {
        ...state,
        connectionFormInputs: action.connectionData,
        config,
      };
    }
    case actions.SET_SUMMARY_LIST: {
      return {
        ...state,
        config: {
          ...state.config,
          summary: action.summaryList,
        },
      };
    }
    

    default:
      return state;
  }
}
