/* eslint-disable linebreak-style */
const promptsActions = {
  FETCH_PROMPTS: 'FETCH_PROMPTS',
  FETCH_PROMPTS_SUCCESS: 'FETCH_PROMPTS_SUCCESS',
  UPDATE_PROMPT: 'UPDATE_PROMPT',
  UPDATE_PROMPT_SUCCESS: 'UPDATE_PROMPT_SUCCESS',
  DELETE_PROMPT: 'DELETE_PROMPT',

  fetchPropmts: () => ({
    type: promptsActions.FETCH_PROMPTS,
  }),

  updatePrompt:(data,cb) => ({
    type: promptsActions.UPDATE_PROMPT,
    data,
    cb
  }),

  deletePrompt:(name,isGlobal, cb) => ({
    type: promptsActions.DELETE_PROMPT,
    name,
    isGlobal,
    cb
  })
};
export default promptsActions;
